import logo from './logo.svg';
import './App.css';
import CricCounterComponent from './components/CricCounterComponent/CricCounterComponent';
function App() {
  return (
    <div className="App">
        <CricCounterComponent />
    </div>
  );
}

export default App;
